import type { Organization, WithContext } from 'schema-dts'

const organizationSchema = {
  'de-de': {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'konfetti',
    url: `https://gokonfetti.com/de-de/`,
    sameAs: [
      'https://www.linkedin.com/company/gokonfetti/',
      'https://www.facebook.com/gokonfetti/',
      'https://www.instagram.com/gokonfetti/',
      'https://www.pinterest.de/gokonfetti/',
    ],
    logo: 'https://gokonfetti.com/logos/full-logo-color-black.svg',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+4930257706800',
        email: 'hallo@gokonfetti.com',
        contactType: 'customer service',
      },
    ],
  },
  'en-us': {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'konfetti',
    url: `https://gokonfetti.com/en-us/`,
    sameAs: [
      'https://www.linkedin.com/company/gokonfetti/',
      'https://www.facebook.com/gokonfetti/',
      'https://www.instagram.com/gokonfetti/',
      'https://www.pinterest.de/gokonfetti/',
    ],
    logo: 'https://gokonfetti.com/logos/full-logo-color-black.svg',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+4930257706800',
        email: 'hallo@gokonfetti.com',
        contactType: 'customer service',
      },
    ],
  },
} as Record<string, WithContext<Organization>>

export default organizationSchema
