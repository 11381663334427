<template>
  <div id="home">
    <BannerGallery />
    <div class="relative sm:hidden">
      <TeamEventRequestButton />
    </div>
    <div v-if="lastViewedEvents?.length > 0">
      <ProductsLoader :loading="loading" is-horizontal class="mt-16">
        <ProductShowcase
          data-test-id="last-viewed-events-section"
          :products="lastViewedEvents"
          :collapse-products="true"
          :mobile-horizontal-scroll="true"
          image-loading="lazy"
          :gtm-item-list-id="GtmItemListId.GeneralPageLastViewedEvents"
          :tags="[]"
          :title-h-class="`legacy-h2`"
          :title="$t('home.lastViewedEvents')"
          algolia-insights-label="Last Viewed Events - Homepage"
        />
      </ProductsLoader>
    </div>

    <ProductsLoader class="mt-16" :loading="loading" is-horizontal>
      <ProductShowcase
        data-test-id="top-events-section"
        :collapse-products="true"
        :mobile-horizontal-scroll="true"
        :products="topEvents"
        image-loading="lazy"
        :gtm-item-list-id="GtmItemListId.GeneralPageTopEvents"
        :query-id="topEventsQueryID"
        :algolia-insights-client="insightsClient"
        :tags="[]"
        :title-h-class="`legacy-h2`"
        :title="$t('home.browseTopEvents')"
        algolia-insights-label="Top Events - Homepage"
      />
    </ProductsLoader>

    <CardsSection
      v-if="cardsSectionContent"
      class="mt-16"
      :content="cardsSectionContent"
    />

    <ProductGiftcardEventsShowcase :title="$t('home.inspirationForYou')" />
    <ProductsLoader class="mt-8 md:mt-16" :loading="loading" is-horizontal>
      <ProductShowcase
        data-test-id="recommended-events-section"
        :products="filteredGiftIdeas"
        :collapse-products="true"
        :mobile-horizontal-scroll="true"
        :query-id="topGiftIdeasEventsQueryID"
        :algolia-insights-client="insightsClient"
        :gtm-item-list-id="GtmItemListId.GeneralPageTopGiftIdeas"
        :tags="[]"
        :title-h-class="`legacy-h2`"
        :title="$t('home.recommendedForYou')"
        algolia-insights-label="Gift Ideas For You - Homepage"
      />
    </ProductsLoader>

    <ProductsLoader class="mt-8 md:mt-16" :loading="loading" is-horizontal>
      <ProductShowcase
        data-test-id="new-events-section"
        :loading="loading"
        :products="newEvents"
        :collapse-products="true"
        :mobile-horizontal-scroll="true"
        :gtm-item-list-id="GtmItemListId.GeneralPageNewEvents"
        :query-id="newEventsQueryID"
        :algolia-insights-client="insightsClient"
        :tags="[]"
        :title-h-class="`legacy-h2`"
        :title="$t('home.newEventsForYou')"
        algolia-insights-label="New Events - Homepage"
      />
    </ProductsLoader>

    <Grid v-if="gridSectionContent" :content="gridSectionContent" />

    <Carousel
      v-if="carousel"
      class="!overflow-visible !w-full"
      :content="carousel"
    />
  </div>
</template>
<script setup lang="ts">
import organizationSchema from '~/constants/organizationSchema'
import { useHomepageAlgolia } from '~/composables/useHomepageAlgolia'
import { eventSectionComposableFactory } from '~/composables/algolia/states'
import { useLastViewedEvents } from '~/composables/useLastViewedEvents'
import { GtmItemListId } from '~/composables/useKftGtm/types'
import { useAlgoliaSearchInsights } from '~/composables/algolia/useAlgoliaSearchInsights'
import { HorizontalCardSection } from '#components'

const { locale } = useI18n()
const currentLocale: string = locale.value
const { client: insightsClient } = useAlgoliaSearchInsights()

const { data: homePageData } = await useStrapiSingleType(
  `home-page?locale=${locale.value}&populate=deep,4`
)

provide('homePageGalleryData', homePageData)

const cardsSectionContent = computed(
  () => homePageData.value?.data?.attributes?.cardSection
)

const gridSectionContent = computed(
  () => homePageData.value?.data?.attributes?.gridSection
)

const carousel = computed(() => homePageData.value?.data?.attributes?.carousel)

const { algoliaResults, pending: loading } = await useHomepageAlgolia()
const useTopEvents = eventSectionComposableFactory(0)
const useNewEvents = eventSectionComposableFactory(1)
const useTopGiftIdeasEvents = eventSectionComposableFactory(2)

const { data: topEvents, queryId: topEventsQueryID } =
  useTopEvents(algoliaResults)
const { data: newEvents, queryId: newEventsQueryID } =
  useNewEvents(algoliaResults)
const { data: topGiftIdeasEvents, queryId: topGiftIdeasEventsQueryID } =
  useTopGiftIdeasEvents(algoliaResults)

const { lastViewedEvents } = useLastViewedEvents(true)

const filteredGiftIdeas = computed(() =>
  topGiftIdeasEvents.value
    .filter(
      (event) =>
        !topEvents.value
          .map((topEvent) => topEvent.objectID)
          .includes(event.objectID)
    )
    .slice(0, 10)
)

useHead({
  script: [
    {
      type: 'application/ld+json',
      innerHTML: organizationSchema[currentLocale],
    },
  ],
})

definePageMeta({
  preloaderType: 'home',
})
</script>
